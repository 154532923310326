import * as React from "react";
import Contact from "./Contact";
import FooterStyle from "./styles/Footer.styled";

const Footer = (): JSX.Element => {
  return (
    <FooterStyle>
      <div className="left">
        <p>adwokat dr Tomasz Zienowicz</p>
        <Contact />
      </div>
    </FooterStyle>
  );
};

export default Footer;
