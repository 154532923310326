import * as React from "react";
import ContactStyle from "./styles/ContactSmall.styled";

interface Props {
  justifyContent: string;
}

const Contact = ({ justifyContent }: Props): JSX.Element => {
  return (
    <ContactStyle justifyContent={justifyContent}>
      <div className="mailbox">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 23"
            width="100%"
            height="100%"
          >
            <path
              id="mail"
              d="M2.69,0A2.67,2.67,0,0,0,0,2.6V20.4A2.67,2.67,0,0,0,2.69,23H29.31A2.67,2.67,0,0,0,32,20.4V2.6A2.83,2.83,0,0,0,29.21,0ZM26.92,2.6,16.75,11.8a1.22,1.22,0,0,1-1.6,0L5,2.6ZM2.69,4.1,10.27,11,2.69,18.5Zm1.7,16.3,7.77-7.7,1.1,1a4,4,0,0,0,2.59,1,3.52,3.52,0,0,0,2.59-1l1.2-1.1,7.77,7.7Zm24.82-2-7.58-7.5L29.21,4Z"
            />
          </svg>
        </div>
        <div>
          <p>kancelaria@zienowicz.com</p>
        </div>
      </div>
      <div className="phonebox">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            height="100%"
            width="100%"
          >
            <path d="M30.23,22A20.32,20.32,0,0,1,24,21a1.74,1.74,0,0,0-1.79.43l-2.79,3.5A27,27,0,0,1,7.12,12.81l3.48-3A1.83,1.83,0,0,0,11,8a20,20,0,0,1-1-6.28A1.78,1.78,0,0,0,8.26,0H2.12C1.15,0,0,.43,0,1.76A30.49,30.49,0,0,0,30.23,32,1.87,1.87,0,0,0,32,30.05c0-.05,0-.1,0-.15V23.77A1.78,1.78,0,0,0,30.23,22Z" />
          </svg>
        </div>
        <div>
          <p>tel. 509 39 19 11</p>
        </div>
      </div>
      <div className="address">
        <p>81-342 Gdynia,</p>
        <p>ul. Waszyngtona 34-36/415</p>
      </div>
    </ContactStyle>
  );
};

export default Contact;
