import styled from 'styled-components';

const Desc = styled.div`
  flex: 10;
  p {
    margin: 0;
    line-height: 1rem;
  }
  small {
    font-size: 0.6rem;
  }
  .title {
    font-weight: bold;
  }
  @media only screen and (max-device-width: 667px) {
    display: none;
  }
`;

export default Desc;
