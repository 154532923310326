import styled from 'styled-components';

const Navbar = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
  a {
    flex: 1;
    color: #425563;
    border-bottom: 1px solid #425563;
    padding: 1rem 1rem 1rem 0;
    text-align: center;
    text-decoration: none;
    border-top-right-radius: 15px;
    &.active {
      background: #efefef;
      color: #425563;
      font-weight: bold;
    }
    @media (max-width: 767px) {
      padding: 0.5rem 0.5rem 0.5rem 0;
      font-size: 0.8rem;
    }
  }
`;

export default Navbar;
