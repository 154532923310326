import styled from "styled-components";

const ContactSmall = styled.div`
  padding: 1rem 0;
  /* text-align: right; */
  flex: 1;
  display: flex;
  flex-direction: column;
  .mailbox,
  .address,
  .phonebox {
    display: flex;
    color: #425563;
    justify-content: ${(props) =>
      props.justifyContent ? `${props.justifyContent}` : ""};
    margin-bottom: 0;
    .icon {
      width: 0.8rem;
      margin-right: 0.5rem;
      svg {
        fill: #425563;
        margin-right: 0.5rem;
      }
    }
    p {
      margin-bottom: 0;
      line-height: 1.3rem;
    }
  }
  .phonebox {
    .icon {
      width: 0.6rem;
    }
  }
  .address {
    flex-direction: column;
  }
  p {
    margin-bottom: 0.3rem;
  }
  @media (min-width: 768px) {
    .phonebox,
    .address,
    .mailbox {
      margin-bottom: 1rem;
      margin-right: 0;
      .icon {
        width: 1.3rem;
      }
    }
    .phonebox {
      .icon {
        width: 1rem;
      }
    }
  }
`;

export default ContactSmall;
