import styled from "styled-components";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  border-bottom: 1px solid #efefef;
  margin-bottom: 0.5rem;
  .address {
    text-align: right;
  }
  @media (max-width: 767px) {
    .mailbox,
    .address,
    .phonebox {
      font-size: 0.7rem;
      padding-right: 1rem;
    }
  }
  @media (min-width: 768px) {
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default Header;
