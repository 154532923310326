import * as React from "react";
import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import Logo from "./Logo";
import Navbar from "./Navbar";
import Contact from "./Contact";
import Container from "./styles/Container.styled";
import Header from "./styles/Header.styled";
import Footer from "./Footer";

interface Props {
  children: JSX.Element[] | JSX.Element;
  theme: string;
}

const GlobalStyle = createGlobalStyle`
  body {
    color: ${(props) => (props.theme === "purple" ? "purple" : "#425563")};
    font-family: Arial, Helvetica, sans-serif ;
    margin:0;
    padding: 0;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  h1,h2,h3,h4,h5,h6{
    color: #32404a;
    margin-bottom: 0.8rem;
  }
  p{
    color:#425563;
    margin-bottom: 0.8rem;
    line-height: 1.5rem;
  }
  ul{
    margin:0;
    padding:0
  }
  li{
    margin-bottom: 1rem;
    color:#425563;
    list-style: none;
  }
  article{
    padding: 1rem;
    margin-bottom: 0;
    h1 {
      border-bottom: 4px solid #efefef;
      padding: 1rem 0;
      margin: 0 0 1rem 0;
    }
    @media (min-width: 991px) {
      margin: 4rem 0;  
    }
  }
`;

const Layout = ({ children, theme }: Props): JSX.Element => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Kancelaria Adwokacka Tomasz Zienowicz | adwokat gdynia</title>
        <meta
          name="description"
          content="Kancelaria Adwokacka Tomasz Zienowicz | adwokat gdynia"
        />
      </Helmet>
      <Container width="1200">
        <Header>
          <Logo />
          <Contact justifyContent="end" />
        </Header>
        <Container width="1200">
          <GlobalStyle theme={theme} />
          <Navbar />
          {children}
        </Container>
      </Container>
      <Container bg="#efefef">
        <Container width="1200">
          <Footer />
        </Container>
      </Container>
    </>
  );
};
export default Layout;
